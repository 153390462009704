.login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  div.login-card {
    min-width: 100%;
    background-color: transparent !important;
    border: none;
  }
  
  .login-forgot {
    text-align: center
  }
  
  div.content {
    background-image: url('/Auth-background.png');
    background-position: center;
    background-size: cover;
  }
  
  .card-header {
    background-color: transparent !important;
    border-bottom: 0px !important;
  }
  
  .card-footer {
    background-color: transparent !important;
    border-top: 0px !important;
  }
  
  .btn-success {
    background-color: #00D06A !important;
    border-color: #00D06A !important;
  }
  
  .loading-spinner {
    position: fixed;
    width: 100%;
    height: 100%;
    background: white url('/Spinner-1s-200px.gif') center center no-repeat;
    background-color: white;
    opacity: .8;
    z-index: 10;
  }